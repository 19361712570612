import React from "react";
import bingLogo from "./assets/bingLogo.png";

const Education = () => {
  return (
    <section id="education" className="education">
      <div className="title">Education</div>
      <div className="education-section">
        <div className="education-main">
          <img
            src={bingLogo}
            alt="Binghamton University Logo"
            className="bingLogo"
          ></img>
          <div className="education-content">
            <div className="education-content-top">
              <div className="education-school">Binghamton University</div>
              <div className="education-date">August '20 - August '24</div>
            </div>
            <div className="education-content-main">ee</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Education;
