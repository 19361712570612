import React from "react";
import ProfilePic from "./assets/profile-pic.jpg";

const Me = () => {
  return (
    <section id="me" className="me">
      <div className="title">Me</div>
      <div className="me-main">
        <img src={ProfilePic} alt="Me" className="img-me"></img>
        <div className="me-intro">
          Hey I'm Adrian! I just graduated from Binghamton University with a
          B.S. in Computer Science and a B.A. in Mathematical Sciences. I'm
          currently working at Khan Sciences, a VC-backed clinical trial startup
          aimed at treating carpal tunnel syndrome, where I'm building the
          software powering the trial. Last year I interned at NYC's
          cybersecurity agency where I built an email threat screaning tool for
          NYC's Security Operations Center. I'm actively looking for software
          engineering roles so feel free to reach out if you like my work!
        </div>
      </div>
    </section>
  );
};

export default Me;
