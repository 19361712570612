import React, { useRef, useEffect, useState } from "react";

const Work = ({ work }) => {
  const projectRef = useRef(null);
  const parallaxLayerRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting);
    }, options);

    let curRef = projectRef.current;
    if (curRef) {
      observer.observe(curRef);
    }

    return () => {
      if (curRef) {
        observer.unobserve(curRef);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (isInView && parallaxLayerRef.current) {
        const scrollY = window.scrollY;
        const speed = window.innerWidth > 900 ? 0.12 : 0.03; // Adjust this value to control parallax speed
        const yPos = -scrollY * speed;
        parallaxLayerRef.current.style.transform = `translateY(${yPos}px)`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isInView]);
  return (
    <div className="work" ref={projectRef}>
      <a
        className="work-video-wrapper"
        target="_blank"
        rel="noopener noreferrer"
        href={work.link}
      >
        <img
          src={work.image}
          alt={`${work.image}`}
          className="work-video"
        ></img>
      </a>
      <div className="work-main" ref={parallaxLayerRef}>
        <div className="work-line">
          <div className="work-title">{work.title}</div>
          <div className="work-location">{work.location}</div>
        </div>
        <div className="work-line">
          <div className="work-company">{work.company}</div>
          <div className="work-date">{work.date}</div>
        </div>
        <div className="work-description">{work.description}</div>
      </div>
    </div>
  );
};

export default Work;
