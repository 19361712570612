import oti from "./assets/videos/oti.jpg";
import onePolicy from "./assets/videos/onePolicy.png";
import dataAnnotation from "./assets/videos/dataAnnotation.png";
import khanSciences from "./assets/videos/khanSciences.png";

const WorkList = [
  {
    id: 1,
    title: "Software Engineer (Contract)",
    company: "Khan Sciences",
    location: "Remote",
    date: "September '24 - Present",
    link: "https://www.khansciences.com/",
    description:
      "Worked alongside 2 other engineers to build, from start to finish, the software behind a VC-backed clinical trial for treating carpal tunnel syndrome. I personally built portions of the frontend using retool to both style components and handle state resulting from API calls. I also wrote and deployed event driven functions to AWS and handled the MongoDB database design and backend routes in our Node.js API.",
    image: khanSciences,
  },
  {
    id: 2,
    title: "Software Development - AI Trainer (Contract)",
    company: "Data Annotation",
    location: "Remote",
    date: "May '24 - August '24",
    link: "https://www.dataannotation.tech/coders?",
    description:
      "I fine-tuned LLMs for major tech clients by testing and fixing AI-generated code in languages such as C++, C#, Python, Java, and JavaScript, improving response accuracy and usability for millions of users. I also engineered and optimized prompts, enhancing LLM capabilities to solve a broader range of technical problems, directly impacting users with more reliable and versatile solutions",
    image: dataAnnotation,
  },
  {
    id: 3,
    title: "Software Engineer Intern",
    company: "Cyber Command (NYC OTI)",
    location: "New York, NY",
    date: "June '23 - August '23",
    link: "https://www.nyc.gov/content/oti/pages/meet-the-team/cyber-command",
    description:
      "Got the opportunity to work in two teams over the summer in the DMARC and Server Coverage projects (outlined below). We worked using an AGILE sprint methodology aided by Jira and weekly sprint planning. In addition to these two projects, I also got to help implement a CI/CD workflow with Terraform and GCP build triggers to deploy IaaS, PaaS, and SaaS services.",
    image: oti,
  },
  {
    id: 4,
    title: "Software Engineer",
    company: "One Policy",
    location: "Binghamton, NY",
    date: "January '23 - May '23",
    link: "https://www.policys.me/",
    description:
      "My friends were talking about a startup they just made while I was in my automata theory class. I asked to join, and got the opportunity to create routes for our Node.js REST API backend to perform CRUD operations, using postman to test them. In addition to working on the backend, I also helped teach the younger students React and advertised the club we made at Binghamton University to teach web devlopment as part of our nonprofit. We ended up disbanding the business due to a lack of coordination that was not sufficient to compete with multibillion dollar businesses, but the experience was great and I got better at learning new technologies in very short time frames.",
    image: onePolicy,
  },
];

export default WorkList;
