import cssIcon from "./assets/me/cssIcon.png";
import htmlIcon from "./assets/me/htmlIcon.png";
import cIcon from "./assets/me/cIcon.png";
import jsIcon from "./assets/me/jsIcon.png";
import gitIcon from "./assets/me/gitIcon.png";
import linuxIcon from "./assets/me/linuxIcon.png";
import bashIcon from "./assets/me/bashIcon.png";
import reactIcon from "./assets/me/reactIcon.png";
import mongoIcon from "./assets/me/mongoIcon.png";
import sqlIcon from "./assets/me/sqlIcon.png";
import dockerIcon from "./assets/me/dockerIcon.png";
import pythonIcon from "./assets/me/pythonIcon.png";
import nodeIcon from "./assets/me/nodeIcon.png";
import googleCloudPlatformIcon from "./assets/me/googleCloudPlatformIcon.png";
import bigQueryIcon from "./assets/me/bigQueryIcon.png";
import cloudFunctionsIcon from "./assets/me/cloudFunctionsIcon.png";
import cloudSchedulerIcon from "./assets/me/cloudSchedulerIcon.png";
import terraformIcon from "./assets/me/terraformIcon.png";
import leagueManager from "./assets/videos/leagueManager.mp4";
import dmarcDemo from "./assets/videos/dmarcDemo.mp4";
import lendahand from "./assets/videos/lendahand.jpg";
import serverCoverage from "./assets/videos/serverCoverage.jpg";
import homeworkManager from "./assets/videos/homeworkManager.mp4";
import postOnce from "./assets/videos/postOnce.png";
import postgresqlIcon from "./assets/me/postgresqlIcon.png";
import firebaseIcon from "./assets/me/firebaseIcon.png";

const ProjectsList = [
  {
    id: 1,
    title: "PostOnce",
    date: "March '24 - Present",
    description:
      "*Tried* being a fitness influencer for a year and got tired of having to post the same video to TikTok, then to Instagram, then to YouTube shorts. I realized I could automate the process, which is where PostOnce was born. I'm still working on it but the goal is to only fill out the video/title/description/etc once in my app, where my app then handles posting to each platform through their respective API's. (So far I've got TikTok working :D) I'm using GCP for hosting this PERN stack app, with Firebase for user auth and a Google Storage Bucket for video storage",
    videoUrl: postOnce,
    link: "https://postonce.io",
    demoIsVideo: false,
    techStack: [
      {
        iconId: 1,
        iconTitle: "PostgreSQL",
        iconUrl: postgresqlIcon,
      },
      {
        iconId: 2,
        iconTitle: "React",
        iconUrl: reactIcon,
      },
      {
        iconId: 3,
        iconTitle: "Node.js",
        iconUrl: nodeIcon,
      },

      {
        iconId: 4,
        iconTitle: "GCP",
        iconUrl: googleCloudPlatformIcon,
      },
      {
        iconId: 5,
        iconTitle: "Firebase",
        iconUrl: firebaseIcon,
      },
      {
        iconId: 7,
        iconTitle: "Git",
        iconUrl: gitIcon,
      },
    ],
  },
  {
    id: 2,
    title: "Dmarcable",
    date: "June '23 - August '23",
    description:
      "An in-house alternative to mxtoolbox to be used by the NYC Security Operations Center to analyze the thousands of suspicious emails sent within city agencies daily. I was given the task of leading the frontend development, which meant designing React components consuming our REST API backend, and keeping the codebase modular, maintainable, and scalable. We split the team of six into backend/frontend, and built the app from scratch in three months.",
    videoUrl: dmarcDemo,
    link: "https://www.nyc.gov/content/oti/pages/meet-the-team/cyber-command",
    demoIsVideo: true,
    techStack: [
      {
        iconId: 1,
        iconTitle: "CSS",
        iconUrl: cssIcon,
      },
      {
        iconId: 2,
        iconTitle: "HTML",
        iconUrl: htmlIcon,
      },
      {
        iconId: 3,
        iconTitle: "Javascript",
        iconUrl: jsIcon,
      },
      {
        iconId: 4,
        iconTitle: "React",
        iconUrl: reactIcon,
      },
      {
        iconId: 5,
        iconTitle: "Python",
        iconUrl: pythonIcon,
      },
      {
        iconId: 6,
        iconTitle: "Docker",
        iconUrl: dockerIcon,
      },
      {
        iconId: 7,
        iconTitle: "Git",
        iconUrl: gitIcon,
      },
    ],
  },
  {
    id: 3,
    title: "Server Coverage",
    date: "June '23 - August '23",
    description:
      "We needed a way to automate checking for outages and additions to the thousands of servers across New York City's 150+ city agencies. My fellow interns and I created a python google cloud function to run at a set interval, performing SQL queries on a table. We first analyzed a month worth of data to figure out thresholds for outages and new equipment that we then used for that function. Thankfully google handled the petabytes of data we threw at it daily.",
    videoUrl: serverCoverage,
    link: "https://www.nyc.gov/content/oti/pages/meet-the-team/cyber-command",
    demoIsVideo: false,
    techStack: [
      {
        iconId: 1,
        iconTitle: "Google Cloud Platform",
        iconUrl: googleCloudPlatformIcon,
      },
      {
        iconId: 2,
        iconTitle: "Big Query",
        iconUrl: bigQueryIcon,
      },
      {
        iconId: 3,
        iconTitle: "Cloud Functions",
        iconUrl: cloudFunctionsIcon,
      },
      {
        iconId: 4,
        iconTitle: "Cloud Scheduler",
        iconUrl: cloudSchedulerIcon,
      },
      {
        iconId: 5,
        iconTitle: "Terraform",
        iconUrl: terraformIcon,
      },
      {
        iconId: 6,
        iconTitle: "Python",
        iconUrl: pythonIcon,
      },
      {
        iconId: 7,
        iconTitle: "SQL",
        iconUrl: sqlIcon,
      },
      {
        iconId: 8,
        iconTitle: "Git",
        iconUrl: gitIcon,
      },
    ],
  },
  {
    id: 4,
    title: "Lendahand",
    date: "February '23",
    description:
      "Signed up for the Binghamton University hackathon with my friends for fun with zero expectations of winning. 24 hours and 0 hours of sleep  later, we had a working MERN stack mobile app and won both the J.P. Morgan Best Civic Engagement Hack and CAE Best Geo Hack. And I got my best sleep of the year. ",
    videoUrl: lendahand,
    link: "https://devpost.com/software/lendahand-oq1snb",
    demoIsVideo: false,
    techStack: [
      {
        iconId: 1,
        iconTitle: "Node.js",
        iconUrl: nodeIcon,
      },
      {
        iconId: 2,
        iconTitle: "MongoDB",
        iconUrl: mongoIcon,
      },
      {
        iconId: 3,
        iconTitle: "CSS",
        iconUrl: cssIcon,
      },
      {
        iconId: 4,
        iconTitle: "HTML",
        iconUrl: htmlIcon,
      },
      {
        iconId: 5,
        iconTitle: "Javascript",
        iconUrl: jsIcon,
      },
      {
        iconId: 6,
        iconTitle: "React",
        iconUrl: reactIcon,
      },
      {
        iconId: 7,
        iconTitle: "Git",
        iconUrl: gitIcon,
      },
    ],
  },
  {
    id: 5,
    title: "Homework Manager",
    date: "January '23 - February '23",
    description:
      "Created a live homework management system using Express, Node, MongoDB, and Mongoose as a backend. Implemented a frontend using Pug with two dynamic components visible at a time that make calls to the REST API backend.",
    videoUrl: homeworkManager,
    link: "https://github.com/sujkovic/inventory-management-app",
    demoIsVideo: true,
    techStack: [
      {
        iconId: 1,
        iconTitle: "Node.js",
        iconUrl: nodeIcon,
      },
      {
        iconId: 2,
        iconTitle: "MongoDB",
        iconUrl: mongoIcon,
      },
      {
        iconId: 3,
        iconTitle: "CSS",
        iconUrl: cssIcon,
      },
      {
        iconId: 4,
        iconTitle: "HTML",
        iconUrl: htmlIcon,
      },
      {
        iconId: 5,
        iconTitle: "Javascript",
        iconUrl: jsIcon,
      },
      {
        iconId: 6,
        iconTitle: "React",
        iconUrl: reactIcon,
      },
      {
        iconId: 7,
        iconTitle: "Git",
        iconUrl: gitIcon,
      },
    ],
  },
  {
    id: 6,
    title: "Sports League Manager",
    date: "March '22 - May '22",
    description:
      "Designed a sports league managing system in C++ with built-in player trading and history tracking conducive to developing object-oriented programming and memory management skills. Implemented dynmically allocated arrays as a fun challenge to learn the rule of three.",
    videoUrl: leagueManager,
    link: "https://github.com/sujkovic/sports-league-manager",
    demoIsVideo: true,
    techStack: [
      {
        iconId: 1,
        iconTitle: "C++",
        iconUrl: cIcon,
      },
      {
        iconId: 2,
        iconTitle: "Bash",
        iconUrl: bashIcon,
      },
      {
        iconId: 3,
        iconTitle: "Linux",
        iconUrl: linuxIcon,
      },
      {
        iconId: 2,
        iconTitle: "Git",
        iconUrl: gitIcon,
      },
    ],
  },
];

export default ProjectsList;
